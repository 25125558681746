<script setup>
    import PrimevueMessage from 'primevue/message';

    const { $api, $me } = useNuxtApp();

    // Initialize i18n
    const { t: $t } = useI18n();
    const localePath = useLocalePath();

    const viewport = useViewport();

    const logout = () => {
        $api.post('logout', {
            onSuccess: () => {
                $me.forget();
                return navigateTo(localePath('index'));
            }
        });
    };

    let menu = [{
        icon: 'material-symbols:dashboard-outline-rounded',
        label: $t('Dashboard'),
        to: localePath('account'),
    }];

    // Add my offers menu if user is validated
    if ($me.isValidated()) {
        menu.push({
            icon: 'ri:auction-line',
            label: $t('My offers'),
            to: localePath('account-offers-waiting'),
        });

        menu = menu.concat([
            {
                icon: 'material-symbols:pallet-outline',
                label: $t('My orders'),
                to: localePath('account-orders-waiting'),
            }, 
            {
                icon: 'material-symbols:receipt-outline',
                label: $t('My invoices'),
                to: localePath('account-invoices'),
            }, {
                icon: 'material-symbols:storefront-outline-rounded',
                label: $t('My addresses'),
                to: localePath('account-addresses'),
            }
        ]);

        menu.push({
            icon: 'material-symbols:chat-outline-rounded',
            label: $t('My messages'),
            to: localePath('account-messages'),
        });
    }
    menu.push({
        icon: 'ri:auction-line',
        label: $t('My litigations'),
        to: localePath('litigations'),
    });

    menu = menu.concat([{
            icon: 'material-symbols:settings-outline-rounded',
            label: $t('My profile'),
            to: localePath('account-profile-id'),
        }, {
            icon: 'material-symbols:logout-rounded',
            label: $t('Logout'),
            class: 'text-red-500',
            command: logout,
        }, {
            icon: 'material-symbols:arrow-left-alt-rounded',
            label: $t('Previous version'),
            to: `${backendUrl()}/profile/me`,
        }
    ]);

    const props = defineProps({
        pageUrl: {
            type: String,
            default: '',
        },
        fullScreen: {
            type: Boolean,
            default: false,
        },
    });

    const showAside = ref(false);

    const toggleAside = () => {
        showAside.value = ! showAside.value;
    }
</script>

<template>
    <NuxtLayout name="frontend" :fullScreen="true">
        <template #breadcrumb>
            <slot name="breadcrumb" />
        </template>

        <div id="account">
            <aside v-if="! props.fullScreen" id="account__menu" :class="{ active: showAside }">
                <PrimevueMenu :model="menu" :pt="{
                    action: ({ props: action }) => ({
                        class: action.item.to === props.pageUrl ? 'router-link-active' : undefined
                    })
                }" />

                <div v-if="viewport.isLessThan('xl')" id="toggle__aside">
                    <PrimevueButton severity="secondary" @click="toggleAside">
                        <Icon :name="showAside ? 'material-symbols:chevron-left-rounded' : 'material-symbols:chevron-right-rounded'" />
                    </PrimevueButton>
                </div>
            </aside>

            <main>
                <slot/>
            </main>
        </div>
    </NuxtLayout>
</template>
